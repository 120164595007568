export enum Resource {
    AUTH = 'auth',
    CONVOCATORIAS = 'convocatorias',
    SUB_CONVOCATORIAS = 'sub-convocatorias',
    RESERVAS = 'reservas',
    EMPRESAS = 'empresas',
    MOTORIZADOS = 'motorizados',
    REMOLQUES = 'remolques',
    CONDUCTORES = 'conductores',
    RECURSOS = 'recursos',
    VIAJES = 'viajes',
    MENSAJES_PROGRAMADOS = 'mensajes-programados',
    PLAN_VIAJE = 'plan-viaje',
    REGLAS = 'reglas',
    CUENTAS = 'cuentas',
    EVENTOS_VALIDACION = 'eventos-validacion',
    REMITOS_DIGITALES = 'remitos-digitales',
    NOVEDADES = 'novedades',
    CONTROL_DESVIOS = 'control-desvio',
    EVENTO_APP = 'evento-app',
    CONTROL_RESERVA = 'control-reserva',
    MOTIVOS = 'motivos',
    FAMILIAS_SERVICIO = 'familias-servicio',
    EXCEL = 'excel',
    PESADAS = 'pesadas'
}
